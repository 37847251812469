import React from "react"
import { Link } from "gatsby"
import BudiUpdated from "../../components/es/updated"

import Layout from "../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../components/es/seo"

import MainImg from "./../../images/main_bg.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"
import logoHeadline from "./../../images/prodctos-en-armonia-con-la-naturaleza.png"
import ourStoryImg from "./../../images/productos-con-enfoque-en-los-ingredientes-de-origen-natural.png"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import product1 from "./../../images/es/moonamar-aceite-de-comino-negro-organico-250-ml-bio.png"
import product1a from "./../../images/es/moonamar-aceite-de-comino-negro-organico-100-ml-bio.png"
import product2 from "./../../images/es/moonamar-aceite-de-comino-negro-en-capsulas-halal.png"
import product2a from "./../../images/es/moonamar-aceite-de-comino-negro-en-capsulas-halal-250.png"
import product3 from "./../../images/es/moonamar-champu-con-aceite-de-argan-y-comino-negro.png"
import product4 from "./../../images/es/moonamar-balsamo-para-piel-con-argan-y-comino-negro.png"
import product5 from "./../../images/es/moonamar-jabon-de-manteca-de-karite-y-comino-negro.png"
import product6 from "./../../images/es/moonamar-caramelos-con-comino-negro-curcuma-y-menta.png"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="IMMMUNO NATURA - PRODUCCIÓN EN ARMONÍA CON LA NATURALEZA"
          description="IMMMUNO NATURA – Selección exclusiva de productos con un enfoque en su origen natural y en sus propiedades beneficiosas."
        />

        <section
          id="_hero"
          className="hero is-fullheight"
          style={{ backgroundImage: `url(${MainImg})` }}
        >
          <div id="bgHero">
            <span className="shape1">
              <img src={shape1} alt="Shape 1" />
            </span>
            <span className="shape2">
              <img src={shape2} alt="Shape 2" />
            </span>
            <span className="shape3">
              <img src={shape3} alt="Shape 3" />
            </span>
            <span className="shape4">
              <img src={shape4} alt="Shape 4" />
            </span>
            <span className="shape5">
              <img src={shape5} alt="Shape 5" />
            </span>
            <span className="shape6">
              <img src={shape6} alt="Shape 6" />
            </span>
            <span className="shape7">
              <img src={shape7} alt="Shape 7" />
            </span>
            <span className="shape8">
              <img src={shape2} alt="Shape 8" />
            </span>
            <span className="shape9">
              <img src={shape6} alt="Shape 9" />
            </span>
            <span className="shape10">
              <img src={shape3} alt="Shape 10" />
            </span>
            <span className="shape11">
              <img src={shape4} alt="Shape 11" />
            </span>
            <span className="shape12">
              <img src={shape1} alt="Shape 12" />
            </span>
            <span className="shape13">
              <img src={shape8} alt="Shape 13" />
            </span>
            <span className="shape14">
              <img src={shape7} alt="Shape 14" />
            </span>
            <span className="shape15">
              <img src={shape3} alt="Shape 15" />
            </span>
          </div>
          <div className="hero-body">
            <div className="container">
              <figure className="image">
                <img
                  src={logoHeadline}
                  alt="Complementos alimenticios y cosméticos"
                />
              </figure>
              <div className="title">EN ARMONÍA </div>
              <div className="subtitle">con la naturaleza</div>
              <div className="_inner">
                <BudiUpdated />
              </div>
            </div>
          </div>
        </section>

        <span className="_line" />

        <section id="our-story" className="section">
          <span className="_dec2">
            <img src={shape8} alt="Dec 2" />
          </span>
          <div className="container">
            <div className="columns">
              <div className="column is-3 _headline">
                <h2 className="title is-2">Selección de productos</h2>
                <p className="content">
                  Los productos MONAMAR son el resultado de una dedicación a la
                  naturaleza y un cuidadoso trabajo en recetas con efectos
                  beneficiosos.
                </p>
              </div>
              <div className="column">
                <figure className="image is-3by4">
                  <img
                    src={ourStoryImg}
                    alt="Productos con enfoque en los ingredientes de origen natural"
                    title="Productos con enfoque en los ingredientes de origen natural"
                  />
                </figure>
              </div>
              <div className="column is-3 content _headline">
                <h2 className="title is-2 is-spaced _gold">INSPIRACIÓN</h2>
                <p className="subtitle is-4">
                  Observa atentamente la naturaleza, entonces podrás entender
                  todo mejor. - <i>Albdert Einstein</i>
                </p>
                <p className="content">
                  Cada persona es única en su aspecto, su sistema endocrino, su
                  sistema inmunológico, así como en sus emociones. Por
                  consiguiente, cada persona desarrolla distintas preferencias y
                  reacciona a los estímulos de su alrededor de diferente modo. Y
                  lo mismo ocurre al elegir la dieta más adecuada, los
                  cosméticos, los complementos alimenticios, etc.
                </p>
                <Link
                  to="/es/inspiracion"
                  title="INSPIRADO EN LA SABIDURÍA DE LA NATURALEZA"
                  className="button is-text is-small btn2"
                >
                  LEER MÁS
                </Link>
              </div>
            </div>
          </div>
          <span className="_dec3">
            <img src={shape6} alt="Dec 3" />
          </span>
        </section>

        <section id="products" className="section">
          <div className="container is-fullhd">
            <div className="columns">
              <div className="column is-3 _headline">
                <h2 className="title is-2 is-spaced">
                  PRODUCTOS IMMMUNO NATURA CON COMINO NEGRO
                </h2>
                <p className="subtitle">
                  Inspirados en la tradición de las grandes culturas y
                  religiones.
                </p>
                <p className="content">
                  Un sistema inmunológico bajo en defensas hace que el cuerpo
                  pueda verse afectado más fácilmente por infecciones. Y un
                  sistema inmunológico falto de equilibrio hace que el cuerpo
                  desarrolle alergias. El comino negro actúa en el equilibrio de
                  nuestras defensas y de este modo ayuda al que nuestro
                  organismo se mantenga sano.
                </p>
                <p className="content">
                  El comino negro ha sido tradicionalmente utilizado contra
                  alergias, como complemento alimenticio para fortalecer el
                  sistema inmunológico y en el cuidado de la piel y del cabello.
                </p>
                <Link
                  to="/es/productos"
                  title="SUPLEMENTOS ALIMENTARIOS Y COSMÉTICOS"
                  className="button is-primary is-rounded is-small btn1head"
                >
                  TODOS LOS PRODUCTOS
                </Link>
              </div>
              <div className="column is-9">
                <Carousel
                  showStatus={false}
                  showIndicators={true}
                  showThumbs={false}
                >
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product1a}
                      alt="ACEITE DE COMINO NEGRO ORGÁNICO 100 ML (BIO)"
                      title="ACEITE DE COMINO NEGRO ORGÁNICO 100 ML (BIO)"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>1</span>
                        <div className="title">
                          Aceite de comino negro ecológico (100 ml)
                        </div>
                        <p className="content">
                          El aceite de comino negro de Immmuno Natura se
                          consigue de un primer prensado y sin otros
                          tratamientos para así poder cuidar todas las
                          propiedades beneficiosas para nuestro organismo que
                          contiene.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/es/producto/aceite-de-comino-negro-ecologico-1"
                              title="ACEITE DE COMINO NEGRO ORGÁNICO 100 ML (BIO)"
                              className="btnMore"
                            >
                              Leer más
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/es/productos"
                          title="SUPLEMENTOS ALIMENTARIOS Y COSMÉTICOS"
                        >
                          TODOS LOS PRODUCTOS
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product1}
                      alt="ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)"
                      title="ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>2</span>
                        <div className="title">
                          Aceite de comino negro ecológico (250 ml)
                        </div>
                        <p className="content">
                          El aceite de comino negro de Immmuno Natura se
                          consigue de un primer prensado y sin otros
                          tratamientos para así poder cuidar todas las
                          propiedades beneficiosas para nuestro organismo que
                          contiene.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/es/producto/aceite-de-comino-negro-ecologico"
                              title="ACEITE DE COMINO NEGRO ORGÁNICO 250 ML (BIO)"
                              className="btnMore"
                            >
                              Leer más
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/es/productos"
                          title="SUPLEMENTOS ALIMENTARIOS Y COSMÉTICOS"
                        >
                          TODOS LOS PRODUCTOS
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product2}
                      alt="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'"
                      title="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>3</span>
                        <div className="title">
                          Aceite de comino negro (90 cápsulas)
                        </div>
                        <p className="content">
                          Las cápsulas de comino negro de IMMMUNO NATURA están
                          hechas en su exterior de un envoltorio de gelatina con
                          certificado “halal” y en su interior, de aceite de
                          comino negro virgen de semillas egipcias.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/es/producto/aceite-de-comino-negro-en-capsulas"
                              title="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'"
                              className="btnMore"
                            >
                              Leer más
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/es/productos"
                          title="SUPLEMENTOS ALIMENTARIOS Y COSMÉTICOS"
                        >
                          TODOS LOS PRODUCTOS
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product2a}
                      alt="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'"
                      title="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>4</span>
                        <div className="title">
                          Aceite de comino negro (250 cápsulas)
                        </div>
                        <p className="content">
                          Las cápsulas de comino negro de IMMMUNO NATURA están
                          hechas en su exterior de un envoltorio de gelatina con
                          certificado “halal” y en su interior, de aceite de
                          comino negro virgen de semillas egipcias.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/es/producto/aceite-de-comino-negro-en-capsulas-1"
                              title="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'"
                              className="btnMore"
                            >
                              Leer más
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/es/productos"
                          title="SUPLEMENTOS ALIMENTARIOS Y COSMÉTICOS"
                        >
                          TODOS LOS PRODUCTOS
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product3}
                      alt="CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO"
                      title="CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>5</span>
                        <div className="title">
                          Champú de argán y comino negro
                        </div>
                        <p className="content">
                          El champú Immmuno Natura de argán y comino negro es
                          ideal para un cuidado del cabello poco agresivo y para
                          personas con el cuero cabelludo sensible.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/es/producto/champu-de-argan-y-comino-negro"
                              title="CHAMPÚ CON ACEITE DE ARGÁN Y COMINO NEGRO"
                              className="btnMore"
                            >
                              Leer más
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/es/productos"
                          title="SUPLEMENTOS ALIMENTARIOS Y COSMÉTICOS"
                        >
                          TODOS LOS PRODUCTOS
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product4}
                      alt="BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO"
                      title="BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>6</span>
                        <div className="title">
                          Bálsamo para la piel con comino negro, argán, jojoba,
                          aguacate y manteca de karité
                        </div>
                        <p className="content">
                          El bálsamo para piel de Immmuno Natura reúne los
                          elementos esenciales para el cuidado de pieles muy
                          secas y/o sensibles.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/es/producto/balsamo-para-la-piel-con-comino-negro-argan-jojoba-aguacate-y-manteca-de-karite"
                              title="BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO"
                              className="btnMore"
                            >
                              Leer más
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/es/productos"
                          title="SUPLEMENTOS ALIMENTARIOS Y COSMÉTICOS"
                        >
                          TODOS LOS PRODUCTOS
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product5}
                      alt="JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO"
                      title="JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>7</span>
                        <div className="title">
                          Jabón de manteca de karité con aceite de comino
                        </div>
                        <p className="content">
                          Jabón de kariteé de Immmuno Natura ofrecea una
                          limpieza suave y agradable a la piel. Especialmente
                          recomendado para personas con alergias cutáneas.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/es/producto/jabon-de-manteca-de-karite-con-aceite-de-comino"
                              title="JABÓN DE MANTECA DE KARITÉ Y COMINO NEGRO"
                              className="btnMore"
                            >
                              Leer más
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/es/productos"
                          title="SUPLEMENTOS ALIMENTARIOS Y COSMÉTICOS"
                        >
                          TODOS LOS PRODUCTOS
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="_slide">
                    <img
                      className="_img"
                      src={product6}
                      alt="CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA"
                      title="CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA"
                    />
                    <div className="_info">
                      <div className="_inner">
                        <span>8</span>
                        <div className="title">
                          Caramelos de comino negro, curcuma, menta y limón
                        </div>
                        <p className="content">
                          Con intenso sabor y buenos para la garganta y las vías
                          respiratorias.
                        </p>
                        <div className="columns is-mobile">
                          <div className="column">
                            <div className="price"></div>
                          </div>
                          <div className="column has-text-right">
                            <Link
                              to="/es/producto/caramelos-de-comino-negro-curcuma-menta-y-limon"
                              title="CARAMELOS CON COMINO NEGRO, CÚRCUMA Y MENTA"
                              className="btnMore"
                            >
                              Leer más
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="_buttonShop">
                        <Link
                          to="/es/productos"
                          title="SUPLEMENTOS ALIMENTARIOS Y COSMÉTICOS"
                        >
                          TODOS LOS PRODUCTOS
                        </Link>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
          <span className="_dec1">
            <img src={shape7} alt="Dec 1" />
          </span>
          <span className="_dec1a">
            <img src={shape3} alt="Dec 1" />
          </span>
        </section>
      </Layout>
    )
  }
}

export default IndexPage
